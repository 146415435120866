import React, { useState } from "react";
import { nl2br } from "../../helpers/StringHelper";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Splash = ({children}) => {
    const { t } = useTranslation();
    const [showSplash, setShowSplash] = useState(true);

    const popupText = nl2br(t("HOME.POPUP_TEXT"));

    const splash = (
        <div className="homepage-popup d-flex justify-content-lg-center align-items-lg-center align-items-sm-stretch">
            <div className="homepage-popup-content align-self-center">
                <div className="col-lg-10 col-xl-6 ml-auto mr-auto text-center">
                    <h1>{t("HOME.POPUP_HEADING")}</h1>
                    <p dangerouslySetInnerHTML={{__html: popupText}}></p>
                </div>
                <div className="col-lg-6 ml-auto mr-auto py-3 text-center d-flex justify-content-center">
                    <span className="popup-button mr-lg-5 mr-sm-4 mr-2" onClick={() => setShowSplash(false)}>{t("HOME.POPUP_BUTTON_CLOSE")}</span>
                    <Link to="/contact" onClick={() => setShowSplash(false)} className="popup-button ml-lg-5 ml-sm-4 ml-2">{t("HOME.POPUP_BUTTON_CONTACT")}</Link>
                </div>
            </div>
        </div>
    );

    if (showSplash) {
        return splash;
    } else {
        return children;
    }
}

export default Splash;