import React from "react";
import { Link } from "react-router-dom";
import {Trans, useTranslation, withTranslation} from "react-i18next";

import Navbar from "../layout/Navbar";
import Footer from "../layout/footer12";
import { homeSliderContent2 } from "../element/SliderContent";
import HomeSlider from "../element/homeslider1";
import ScrollToTop from "../element/scrollToTop";

const HomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar isMainPage={true} />

      <div className="hero-section relative video-section overlay3 home-slider-12">
        <HomeSlider data={homeSliderContent2} hideBtn />

        <div className="hero-content text-center content-center">
          <div
            className="description mt-4"
            style={{ fontSize: 40, lineHeight: "2.5rem", color: "rgba(255,255,255,0.7)" }}>
            {t("HOME.MAIN_HEADER")} <span className="text-nowrap">{t("HOME.SPZOO")}</span>
          </div>
          <div className="description mt-4" style={{ fontSize: 20 }}>
            {t("HOME.SECONDARY_HEADER")}
          </div>
          <div className="description mt-4" style={{ fontSize: 16 }}>
            {t("HOME.THIRD_HEADER")}
          </div>
          <Link to="/contact" className="site-button" style={{ minWidth: 220 }}>
            <i class="la la-phone icon"></i>
            {t("CONTACT.CONTACT_US")}
          </Link>
          <Link to="/offer" className="site-button" style={{ minWidth: 220 }}>
            <i class="la la-file-text icon"></i>
            {t("OFFER.OUR_OFFER")}
          </Link>
        </div>
      </div>

      <div class="page-content bg-white">
        <div class="content-block">
          <div class="section-full content-inner bg-white">
            <div class="container">
              <div class="row align-items-begin">
                {i18n.language === "pl" && (
                <div class="col-lg-12 m-b30">
                  <span className="subtitle">Podkłady kolejowe</span>
                  <h2 class="title">Szkodliwość Podkładów kolejowych</h2>
                  <iframe width="100%" height="640" src="https://www.youtube.com/embed/wrEQKJsAzSE"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen></iframe>
                </div>
                )}
                <div class="col-lg-6 col-md-12 m-b30 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                  <div class="our-story">
                    <span>{t("HOME.OUR_STORY")}</span>
                    <h2 class="title">
                      <Trans i18nKey="HOME.TITLE" components={{ span: <span className="text-primary" /> }} />
                    </h2>
                    <p>{t("HOME.TEXT")}</p>
                    <Link to="/about" class="site-button btnhover20 text-capitalize">
                      {t("ABOUT.HEADER")}
                    </Link>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 m-b30 text-right" data-wow-duration="2s" data-wow-delay="0.3s">
                  <img src={require("../../images/own/20210331_113612.jpg")} class="radius-sm mb-2" alt="" />
                  <img src={require("../../images/own/offer-2.jpg")} class="radius-sm mb-2" alt="" />
                  <img src={require("../../images/own/about-1.jpg")} class="radius-sm mb-2" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop className="scroltop style2 radius" />
    </>
  )
}

export default HomePage
