import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";
import Navbar from "../layout/Navbar";
import Footer from "../layout/footer12";

var bnrAbout = require("./../../images/own/about-c1.jpg");

class AboutPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar />

        <div className="page-content bg-white">
          <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnrAbout + ")" }}>
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white text-capitalize">{t("ABOUT.HEADER")}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"} className="text-capitalize">
                        {t("NAVBAR.HOME")}
                      </Link>
                    </li>
                    <li className="text-capitalize">{t("ABOUT.HEADER")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block">
            <div className="section-full content-inner bg-white">
              <div className="container">
                <div className="row align-items-start">
                  <div className="col-lg-6 col-md-12 m-b30">
                    <div className="our-story">
                      <span>{t("ABOUT.OUR_STORY")}</span>
                      <h2 className="title">
                        <Trans i18nKey="ABOUT.TITLE" components={{ span: <span className="text-primary" /> }} />
                      </h2>
                      <h4 className="title">{t("ABOUT.TEXT.1")}</h4>
                      <p>{t("ABOUT.TEXT.2")}</p>
                      <p className="">{t("ABOUT.TEXT.3")}</p>
                      <p className="">{t("ABOUT.TEXT.4")}</p>
                      <p className="">{t("ABOUT.TEXT.5")}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 m-b30">
                    <img src={require("../../images/own/pludry-1975.png")} className="radius-sm mb-2" alt="" />
                    <img src={require("../../images/own/Pludry-1975-2.png")} className="radius-sm mb-2" alt="" />
                    <img src={require("../../images/own/20210331_113444.jpg")} className="radius-sm" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(AboutPage);
