import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Navbar from "../layout/Navbar";
// import Footer from "../layout/footer";
import Footer from "../layout/footer12";
import Portfolio from "../element/portfolio";

const bgOffer = require("../../images/own/offer-c8.jpg");

class OfferPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar />
        <div class="page-content bg-white">
          <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bgOffer + ")" }}>
            <div class="container">
              <div class="dlab-bnr-inr-entry">
                <h1 class="text-white text-capitalize">{t("OFFER.HEADER")}</h1>
                <div class="breadcrumb-row">
                  <ul class="list-inline">
                    <li>
                      <Link to="/" className="text-capitalize">
                        {t("NAVBAR.HOME")}
                      </Link>
                    </li>
                    <li className="text-capitalize">{t("OFFER.HEADER")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <Portfolio col="col-md-4" />
        </div>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(OfferPage);
