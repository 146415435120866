import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../layout/Navbar";
import Footer from "../layout/footer12";
import { Form } from "../element/Form";

const bg = require("../../images/own/unsplash/slider-1.jpg");

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Navbar />
      <div class="page-content bg-white">
        <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
          <div class="container">
            <div class="dlab-bnr-inr-entry">
              <h1 class="text-white text-capitalize">{t("CONTACT.HEADER")}</h1>
              <div class="breadcrumb-row">
                <ul class="list-inline">
                  <li>
                    <Link to="index" className="text-capitalize">
                      {t("NAVBAR.HOME")}
                    </Link>
                  </li>
                  <li className="text-capitalize">{t("CONTACT.HEADER")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="section-full content-inner contact-page-8 bg-white">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-12">
                <div class="row">
                  <div class="col-lg-12 col-md-12 m-b30">
                    <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm text-primary">
                            <i class="ti-location-pin"></i>
                          </span>
                          {t("CONTACT.COMPANY_ADDRESS")}
                        </h5>
                        <p>
                          Nasycalnia Podkładów Sp. z o. o.
                          <br />
                          Al. Wyzwolenia 18
                          <br />
                          46-375 Pludry
                        </p>
                        <h6 class="m-b15 text-black font-weight-400">
                          <i class="ti-alarm-clock"></i> {t("CONTACT.OFFICE_HOURS")}
                        </h6>
                        <p class="m-b0">{t("CONTACT.OFFICE_HOURS_VALUE")}</p>
                        <p>{t("CONTACT.SUNDAY_CLOSE")}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 m-b30">
                    <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm text-primary">
                            <i class="ti-email"></i>
                          </span>
                          E-mail
                        </h5>
                        <p class="m-b0">nasycalnia@pludry.eu</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-6 m-b30">
                    <div class="icon-bx-wraper expertise bx-style-1 p-a20 radius-sm">
                      <div class="icon-content">
                        <h5 class="dlab-tilte">
                          <span class="icon-sm text-primary">
                            <i class="ti-mobile"></i>
                          </span>
                          {t("CONTACT.PHONE_NUMBERS")}
                        </h5>
                        <p class="m-b0">+48-34-353-73-94</p>
                        <p class="m-b0">+48-34-353-73-76</p>
                        <p class="m-b0">+48-34-353-73-68</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-md-12 m-b30">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ContactPage;
