import React, { Suspense } from "react";
import Router from "./markup/router";
import "./App.css";

import "./plugins/fontawesome/css/font-awesome.min.css";
import "./plugins/line-awesome/css/line-awesome.min.css";
import "./plugins/flaticon/flaticon.css";
import "./plugins/flaticon/beer/flaticon.css";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-CBY4XG4H05'
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <div className="App">
      <Suspense fallback="loading">
          <Router />
      </Suspense>
    </div>
  );
};

export default App;
