import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import { withTranslation } from "react-i18next";

class Navbar extends Component {
  state = {
    fixed: false,
    isDrawerHidden: true,
  };

  componentDidMount() {
    // sidebar open/close

    var btn = document.querySelector(".navicon");
    var nav = document.querySelector(".header-nav");
    var logoNavbar = document.getElementById("logo-navbar");
    var logoDrawer = document.getElementById("logo-drawer");

    function toggleFunc(setStateFun, isDrawerHidden) {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
      logoNavbar.classList.toggle("hide-logo");
      logoDrawer.classList.toggle("show-drawer");
    }

    btn.addEventListener("click", toggleFunc);

    // Sidenav li open close
    var navUl = [].slice.call(document.querySelectorAll(".header-nav > ul > li"));
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }

    function checkLi(current) {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open");
        console.log("active");
      } else {
        current.classList.add("open");
        console.log("close");
      }
    }
  }

  handleStateChange = (status) => {
    const fixed = status.status === Sticky.STATUS_FIXED ? true : false;

    this.setState({
      fixed,
    });
  };

  toggleLanguage = () => {
    console.log({ props: this.props });
    const { i18n, t } = this.props;
    const currentLang = i18n.language;
    if (currentLang === "en") {
      return i18n.changeLanguage("pl");
    }
    if (currentLang === "pl") {
      return i18n.changeLanguage("en");
    }
    return i18n.changeLanguage("pl");
  };

  getLanguageName = (lang) => {
    const opts = {
      en: "POLSKI",
      pl: "ENGLISH",
    };
    return opts[lang] ?? "POLSKI";
  };

  render() {
    const active = this.state.fixed ? "is-fixed" : "";
    const {
      i18n: { language },
      t,
    } = this.props;
    return (
      <>
        <Sticky innerZ={999} enabled={true} onStateChange={this.handleStateChange}>
          <header className={`site-header mo-left header navstyle2`}>
            {/* <header className="site-header mo-left header header-transparent navstyle2"> */}
            <div className={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}>
              <div className="main-bar clearfix ">
                <div className="container clearfix">
                  <div className="logo-header mostion mt-md-3" id="logo-navbar">
                    <Link to="/">
                      <img className="logo-navbar" src={require("../../images/logo/Logo_NP.svg")} alt="" />
                    </Link>
                  </div>

                  <button
                    className="navbar-toggler collapsed navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => this.setState({ isDrawerHidden: !this.state.isDrawerHidden })}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  {/* Drawer for mobile */}
                  <div
                    className="header-nav navbar-collapse collapse justify-content-end drawer-nav"
                    id="navbarNavDropdown">
                    <div
                      className={`my-3 logo-drawer`}
                      style={{ display: this.state.isDrawerHidden ? "none" : "" }}
                      id="logo-drawer">
                      <Link to="/">
                        <img src={require("../../images/logo/Logo_NP.svg")} alt="" />
                      </Link>
                    </div>
                    <ul className={`nav navbar-nav text-uppercase`}>
                      {/* NavBar */}
                      <li className="drawer-item">
                        <Link to={"/"}>
                          {t("NAVBAR.HOME")}
                          <i className=""></i>
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link to={"/about"}>
                          {t("NAVBAR.ABOUT_US")}
                          <i className=""></i>
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link to={"/offer"}>
                          {t("NAVBAR.WHAT_WE_OFFER")}
                          <i className=""></i>
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link to={"/treatment"}>
                          {t("NAVBAR.TREATMENT")}
                          <i className=""></i>
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link to={"/utilization"}>
                          {t("NAVBAR.UTILIZATION")}
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link to={"/contact"}>
                          {t("NAVBAR.CONTACT")}
                          <i className=""></i>
                        </Link>
                      </li>
                      <li className="drawer-item">
                        <Link onClick={this.toggleLanguage} to="#">
                          {this.getLanguageName(language)}
                          <i className=""></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Sticky>
      </>
    );
  }
}
export default withTranslation()(Navbar);
