import React, {Component} from "react";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import pfRegulamin from "../../pdfs/Regulamin_obiektu_infrastruktury_uslugowej_Nasycalni_w_Pludrach.pdf";
import pfStatut from "../../pdfs/Statut_sieci_Nasycalni_w_Pludrach.pdf";
import footer from "./footer";

// const bg = require("../../images/footer/alex-chernenko-C1SOIqlFd6I-unsplash.jpg");
const bg = require("../../images/footer/simon-harvey-simhxrvey-6Jkru6FSX90-unsplash.jpg");

class Footer12 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docs: []
        };
    }

    componentDidMount() {
        fetch(process.env.REACT_APP_BACKEND_URL + "/documents/list").then((response) => {
            return response.json();
        }).then((data) => {
            console.log(data);
            this.setState({docs: data});
        });
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <footer className="site-footer">
                    <div className="footer-top overlay-black-light" style={{backgroundImage: "url(" + bg + ")"}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-4 col-lg-4 footer-col-6">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">
                                            {t("FOOTER.COMPANY")}
                                        </h5>
                                        <ul>
                                            <li>
                                                <Link to="/about">{t("NAVBAR.ABOUT_US")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/offer">{t("NAVBAR.WHAT_WE_OFFER")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/treatment">{t("NAVBAR.TREATMENT")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/utilization">{t("NAVBAR.UTILIZATION")}</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact">{t("NAVBAR.CONTACT")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-4 footer-col-6">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">
                                            {t("FOOTER.DOCUMENTS")}
                                        </h5>
                                        <ul>
                                            {this.state.docs.map((doc, index) => (
                                                <li key={'document-' + index}>
                                                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                                                        {doc.nice_name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-4 col-lg-4 footer-col-6">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white ">{t("FOOTER.CONTACT")}</h5>
                                        <ul>
                                            <li>
                                                <i className="ti-location-pin"></i>
                                                <strong>{t("FOOTER.ADDRESS")}</strong>
                                                <a href="https://goo.gl/maps/2BRtXz1J5RS6JFJt7" target="_blank"
                                                   rel="noopener noreferrer">
                                                    Al. Wyzwolenia 18, 46-375 Pludry
                                                </a>
                                            </li>
                                            <li>
                                                <i className="ti-mobile"></i>
                                                <strong>{t("FOOTER.PHONE")}</strong>
                                                <a href="tel:+48343537394">+48-34-353-73-94</a>
                                            </li>
                                            <li>
                                                <i className="ti-email"></i>
                                                <strong>email</strong>
                                                <a href="mailto:nasycalnia@pludry.eu">nasycalnia@pludry.eu</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 text-left ">
                                    <p>Copyright © 2019 Nasycalnia Podkładów Sp. z o. o.</p>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default withTranslation()(Footer12);
