import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Navbar from "../layout/Navbar";
import Footer from "../layout/footer12";

const bg = require("../../images/own/unsplash/slider-2.jpg");

class TreatmentPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar />
        <div class="bg-white">
          <div class="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bg + ")" }}>
            <div class="container">
              <div class="dlab-bnr-inr-entry">
                <h1 class="text-white text-capitalize">{t("TREATMENT.HEADER")}</h1>
                <div class="breadcrumb-row">
                  <ul class="list-inline">
                    <li>
                      <Link to="/" className="text-capitalize">
                        {t("NAVBAR.HOME")}
                      </Link>
                    </li>
                    <li className="text-capitalize">{t("TREATMENT.HEADER")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-full content-inner-2 bg-primary overlay-primary-middle">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 service-main-title text-white text-center">
                <h3 class="title max-w1200 m-auto treatment-h3">{t("TREATMENT.PRIMARY_TEXT")}</h3>
              </div>
            </div>
          </div>
        </div>

        <div class="section-full">
          <div class="row spno about-industry">
            <div class="col-lg-4">
              <img src={require("../../images/own/treatment-c1.jpg")} alt="" class="img-cover" />
            </div>

            <div class="col-lg-4 bg-secondry text-white">
              <div class="service-box style2">
                <div>
                  <p>{t("TREATMENT.SECONDARY_TEXT")}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <img src={require("../../images/own/treatment-c2.jpg")} alt="" class="img-cover" />
            </div>
            <div class="col-lg-4">
              <img src={require("../../images/own/treatment-c3.jpg")} alt="" class="img-cover" />
            </div>
            <div class="col-lg-4">
              <img src={require("../../images/own/treatment-c4.jpg")} alt="" class="img-cover" />
            </div>
            <div class="col-lg-4">
              <img src={require("../../images/own/treatment-c7.jpg")} alt="" class="img-cover" />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default withTranslation()(TreatmentPage);
