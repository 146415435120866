import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Form = () => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = (e) => {
    if (isSending) return;
    setIsSending(true);
    e.preventDefault();

    emailjs
      .send(
        "pludry",
        "pludry-contact-form",
        { firstName, lastName, company, email, phone, message },
        "user_iSHuRVkouXliZChU0ZVHJ"
      )
      .then(
        (result) => {
          // console.log("result", result.text);
          toast.success(t("CONTACT.FORM.SUCCESS"), {
            className: "toast-success",
          });
        },
        (error) => {
          // console.log("error", error.text);
          toast.error(t("CONTACT.FORM.FAILURE"), {
            className: "toast-error",
          });
        }
      )
      .finally(() => resetState());
  };

  const resetState = () => {
    setFirstName("");
    setLastName("");
    setCompany("");
    setEmail("");
    setPhone("");
    setMessage("");
    setIsSending(false);
  };

  useEffect(() => {
    if (firstName && lastName && email && message && !isSending) setIsSubmitDisabled(false);
    if ((!firstName || !lastName || !email || !message || isSending) && !isSubmitDisabled) setIsSubmitDisabled(true);
  }, [firstName, lastName, email, message, isSubmitDisabled, isSending]);

  return (
    <form class="inquiry-form wow fadeInUp" data-wow-delay="0.2s">
      <h4 class="title-box font-weight-300 m-t0 m-b10">{t("CONTACT.FORM.HEADER")}</h4>
      <p>{t("CONTACT.FORM.DESCRIPTION")}</p>
      <div class="row">
        <div class="col-lg-6 col-md-6">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-user text-primary"></i>
              </span>
              <input
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                required
                class="form-control"
                placeholder={t("CONTACT.FORM.FIRST_NAME")}
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-user text-primary"></i>
              </span>
              <input
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                required
                class="form-control"
                placeholder={t("CONTACT.FORM.LAST_NAME")}
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-id-badge text-primary"></i>
              </span>
              <input
                name="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                type="email"
                class="form-control"
                required
                placeholder={t("CONTACT.FORM.COMPANY")}
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-email text-primary"></i>
              </span>
              <input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                class="form-control"
                required
                placeholder={t("CONTACT.FORM.EMAIL")}
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-mobile text-primary"></i>
              </span>
              <input
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                class="form-control"
                required
                placeholder={t("CONTACT.FORM.PHONE")}
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="form-group">
            <div class="input-group">
              <span class="input-group-addon">
                <i class="ti-agenda text-primary"></i>
              </span>
              <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
                class="form-control"
                required
                placeholder={t("CONTACT.FORM.MESSAGE")}></textarea>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <button
            name="submit"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            style={{ opacity: isSubmitDisabled ? 0.5 : 1 }}
            class="site-button button-md">
            <span>{t("CONTACT.FORM.SUBMIT")}</span>
          </button>
        </div>
      </div>
    </form>
  );
};
