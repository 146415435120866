import React from 'react';
import Navbar from "../layout/Navbar";
import {Link} from "react-router-dom";
import Footer from "../layout/footer12";
import { useTranslation } from "react-i18next";
import { nl2br } from "../../helpers/StringHelper";
import bnrAbout from "../../images/own/about-c1.jpg";
const Utilization = () => {
    const { t } = useTranslation();

    return (
        <>
            <Navbar />

            <div className="page-content bg-white">
                <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnrAbout + ")" }}>
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <h1 className="text-white text-capitalize">{t("UTILIZATION.HEADER")}</h1>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <li>
                                        <Link to={"/"} className="text-capitalize">
                                            {t("NAVBAR.HOME")}
                                        </Link>
                                    </li>
                                    <li className="text-capitalize">{t("UTILIZATION.HEADER")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-block">
                    <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="row align-items-start">
                                <div className="col-lg-6 col-md-12 m-b30">
                                    <div className="our-story">
                                        <h2 className="title">{t("UTILIZATION.TITLE")}</h2>
                                        <p dangerouslySetInnerHTML={{__html: nl2br(t("UTILIZATION.TEXT.1"))}}></p>
                                        <p className="font-weight-bold" dangerouslySetInnerHTML={{__html: nl2br(t("UTILIZATION.TEXT.2"))}}></p>
                                        <p dangerouslySetInnerHTML={{__html: nl2br(t("UTILIZATION.TEXT.3"))}}></p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 m-b30">
                                    <img src={require("../../images/utilization/1.jpg")} className="radius-sm mb-2" alt="" />
                                    <img src={require("../../images/utilization/2.jpeg")} className="radius-sm mb-2" alt="" />
                                    <img src={require("../../images/utilization/3.jpeg")} className="radius-sm" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Utilization;