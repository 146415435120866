import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Portfolio = (props) => {
  const { t } = useTranslation();

  const images = [
    {
      id: 1,
      name: "agriculture",
      title: t("OFFER.RAILWAY_SLEEPERS"),
      imgUrl: require("../../images/own/offer-c1.jpg"),
    },
    {
      id: 2,
      name: "agriculture",
      title: t("OFFER.TURNOUT_SLEEPERS"),
      imgUrl: require("../../images/own/offer-c2.jpg"),
    },
    {
      id: 3,
      name: "agriculture",
      title: t("OFFER.BRIDGE_SLEEPERS"),
      imgUrl: require("../../images/own/offer-c3.jpg"),
    },
    {
      id: 4,
      name: "chemical",
      title: t("OFFER.MACHINE_ROUNDED_POSTS"),
      subTitle: t("OFFER.FENCING_AGRICULTURE"),
      imgUrl: require("../../images/own/offer-c4.jpg"),
    },
    {
      id: 5,
      name: "chemical",
      title: t("OFFER.PEELED_POSTS"),
      subTitle: t("OFFER.FENCING_AGRICULTURE"),
      imgUrl: require("../../images/own/offer-c5.jpg"),
    },
    {
      id: 6,
      name: "chemical",
      title: t("OFFER.SAWN_TIMBER_OF_DIFFERENT_SIZES"),
      subTitle: t("OFFER.FENCING_AGRICULTURE_CONSTRUCTION"),
      imgUrl: require("../../images/own/offer-c6.jpg"),
    },
    {
      id: 7,
      title: t("OFFER.UTILITY_POLES"),
      imgUrl: require("../../images/own/offer-c7.jpg"),
    },
    {
      id: 8,
      title: t("OFFER.REPROCESSING"),
      imgUrl: require("../../images/own/podklady-kolejowe-rybnik-484735250.jpg"),
      url: '/utilization'
    },
  ];

  const collumnCls = props.col ? props.col : "col-md-3";

  return (
    <div class="content-block">
      <div class="section-full content-inner-2 portfolio bg-gray" id="portfolio">
        <div class="container">
          <div class="section-full portfolio bg-gray px-2">
            <h2 className="mb-5">{t("OFFER.TITLE")}</h2>
            <p className="mb-4">{t("OFFER.TEXT")}</p>
            <p className="mb-4">{t("OFFER.WE_SUPPLY")}</p>
          </div>

          {/* FILTERED PORTFOLIO LIST */}
          <div className="portfolio_area text-uppercase">
            <div className="row portfolio-grid">
              {images.map((image) => (
                <div className={collumnCls}>
                  <div class="dlab-box dlab-gallery-box">
                    <div class="">
                      <img src={image.imgUrl} alt="" />
                    </div>
                    <div class="dez-info p-a30 bg-white">
                      <p class="dez-title m-t0">
                        <Link to={image?.url || "/"}>{image.title}</Link>
                      </p>
                      <p>
                        <small>{image.subTitle}</small>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
